import React from "react"
import SEO from "../components/seo.js"
import PageHeader from "../components/page-header.js"
import { Link } from "gatsby"

const ArticleList = ({ posts }) => {
  return (
    <section className="mw7 center avenir">
	  <SEO title="News" />
	  <PageHeader headerText="News" />
      {posts.map(({ node: post }) => {
        return (
          <article key={post.id} className="pv4 bb b--black-10 ph3 ph0-l">
            <div className="w-100 w-60-ns order-2 order-1-ns center">
              <Link
                className="db ph0-l no-underline black dim"
                to={`/news${post.fields.slug}`}
				
              >
                <h1 style={{color:"darkred"}} className="f3 fw1 avenir mt0 blue lh-title">
                  {post.frontmatter.title}
                </h1>
                <p className="f6 f5-l lh-copy fw1">{post.excerpt}</p>
              </Link>
              <time className="f6 db gray">{post.frontmatter.date}</time>
            </div>
          </article>
        )
      })}
    </section>
  )
}

export default ArticleList